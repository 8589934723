<!--TableTheadThComponent.vue.vue-->
<template>
  <th
    class="p-12 text-sm text-on-surface-elevation-4 leading-md align-middle bg-[#F2F6FF] first:rounded-l-2xl last:rounded-r-2xl first:pl-20 last:pr-20"
    :class="`${aCenter ? 'text-center' : 'text-left'} ${thClass}`"
  >
    <div
      v-if="(thTitle && thTitle.length > 0) || checkboxUse"
      class="flex gap-4 items-center"
      :class="{
        'justify-center': aCenter
      }"
    >
      <div v-if="checkboxUse" class="shrink-0 leading-[1] mr-4">
        <checkbox
          :id="uuid"
          v-model="checkBoxValue"
          :options="{
            size: 'sm',
            align: 'middle'
          }"
          :name="name"
          :disabled="checkBoxDisabled"
          :state="state"
          @update:modelValue="$emit('onCheckboxUpdate', $event)"
        />
      </div>
      <st-tooltip-text-over v-if="tooltipUse" :customCss="tooltipCustomCss" :content="`${thTitleTooltip ? thTitleTooltip : thTitle}`" hasArrow>
        <safe-html :html="thTitle || ''" class="whitespace-nowrap overflow-hidden text-ellipsis inline-block align-top font-medium" />
      </st-tooltip-text-over>
      <safe-html
        v-else
        tag="span"
        class="whitespace-nowrap overflow-hidden text-ellipsis max-w-full inline-block align-top font-medium cursor-default tracking-[0]"
        :html="thTitle || ''"
      />
      <div v-if="sortingUse" class="shrink-0 leading-[1]">
        <button type="button" class="h-16" @click="onSort()">
          <s-icon
            size="xl"
            :icon="getSortIcon"
            :class="{
              'text-on-surface-elevation-4': sortingDefault,
              'text-on-surface-elevation-2': sortingUp || sortingDown
            }"
            class="flex"
          />
        </button>
      </div>
      <div v-if="popupUse" class="shrink-0 leading-[1]">
        <button type="button" class="h-16" @click="$emit('onPopupClick')">
          <s-contents-popup
            :distance="12"
            :offset="[0, 0]"
            placement="bottom-start"
            trigger="click"
            :minWidth="328"
            :flipOnUpdate="false"
          >
            <template #target>
              <s-icon
                size="xl"
                icon="ic-v2-state-help-circle-line"
                class="text-on-surface-elevation-4 flex"
                title="Help icon"
              />
            </template>
            <template #header>
              <s-contents-popup-header />
            </template>
            <template #contents>
              <s-contents-popup-content-body>
                <slot name="popup-content"></slot>
              </s-contents-popup-content-body>
            </template>
          </s-contents-popup>
        </button>
      </div>
      <div v-if="isShowIcon" class="shrink-0 leading-[1]">
        <s-tooltip
          arrow
          :content="`<p class='text-center'>${tooltipText}</p>`"
          :duration="0"
          :distance="distance || 4"
          :offset="offset || [0, 0]"
          useFlip
          flipOnUpdate
          :placement="tooltipPlacement || 'bottom'"
          trigger="click"
          :theme="'studio-tooltip'"
          :zIndex="2501"
          :allowHTML="true"
          :class="'cursor-auto'"
          @show="handleTooltipShow"
          @hide="handleTooltipHide"
        >
          <template #target>
            <s-icon
              size="xl"
              icon="ic-v2-state-info-circle-line"
              class="text-on-surface-elevation-4"
            />
          </template>
        </s-tooltip>
      </div>
    </div>
    <slot></slot>
  </th>
</template>

<script setup lang="ts">
import type { AllIcons } from '@stove-ui/vue';
import { computed, defineModel, onBeforeUnmount, ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { SORT_VALUE } from '@/constants/common.const';
import { generateUUID } from '@/utils/uuid.util';

const props = defineProps<{
  sortValue?: number;
  thTitle?: string;
  sortingUse?: boolean;
  checkboxUse?: boolean;
  aCenter?: boolean;
  sortingDefault?: boolean;
  sortingUp?: boolean;
  sortingDown?: boolean;
  popupUse?: boolean;
  name?: string;
  tooltipUse?: boolean;
  tooltipText?: string;
  isShowIcon?: boolean;
  tooltipPlacement?:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end';
  offset?: [number, number];
  state?: string;
  thTitleTooltip?: string;
  thClass?: string;
  thTitleClass?: string;
  distance?: number;
}>();

const emit = defineEmits<{
  onSort: [sortValue: number];
  onCheckboxUpdate: [checked: boolean];
  onPopupClick: [];
}>();

const checkBoxValue = defineModel<boolean>('checkBoxValue', { default: false });
const checkBoxDisabled = defineModel<boolean>('checkBoxDisabled');
const tooltipCustomCss = computed(() => {
  if (props.isShowIcon && props.sortingUse) {
    return '!max-w-[72%]';
  }
  if (props.isShowIcon || props.sortingUse) {
    return '!max-w-[85%]';
  }
  return '';
});

const uuid: string = `st-th_${generateUUID()}`;

const onSort = () => {
  let newStatus = SORT_VALUE.ASC;
  switch (props.sortValue) {
    case SORT_VALUE.NONE:
      newStatus = SORT_VALUE.ASC;
      break;
    case SORT_VALUE.ASC:
      newStatus = SORT_VALUE.DESC;
      break;
    default:
      newStatus = SORT_VALUE.NONE;
      break;
  }
  emit('onSort', newStatus);
};

const getSortIcon = computed<AllIcons>(() => {
  switch (props.sortValue) {
    case SORT_VALUE.ASC:
      return 'ic-v2-community-sort-ascending-line';
    case SORT_VALUE.DESC:
      return 'ic-v2-community-sort-descending-line';
    default:
      return 'ic-v2-control-sort-arrow-line';
  }
});

const isTooltipVisible = ref(false);
let timeoutId: ReturnType<typeof setTimeout> | null = null;

const handleTooltipShow = ($event: { hide: () => void }) => {
  isTooltipVisible.value = true;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(() => {
    if (isTooltipVisible.value) {
      $event.hide();
      isTooltipVisible.value = false;
    }
  }, 3000);
};

const handleTooltipHide = () => {
  isTooltipVisible.value = false;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
};

onBeforeUnmount(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});
</script>
